#nprogress .bar {
	background: var(--c__green);
}

/* Fancy blur effect */
#nprogress .peg {
	box-shadow: 0 0 10px var(--c__green), 0 0 5px var(--c__green);
}

#nprogress .spinner-icon {
	border-top-color: var(--c__green);
	border-left-color: var(--c__green);
}
