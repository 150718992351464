$c-light: #fff;
$c-dark: #222;

$c-default: #ccc;
$c-info: #569cff;
$c-success: #91ba5b;
$c-warning: #f4b93a;
$c-error: #ef6461;

.notifications {
	position: fixed;
	top: 15px;
	//bottom: 15px;
	right: 15px;
	z-index: 9999999999999;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	.notification {
		max-width: 400px;
		width: 100%;
		margin-bottom: 15px;
		padding: 15px;
		background-color: var(--c-grey-400);
		border-radius: 6px;
		font-size: 16px;
		line-height: 1.2;
		position: relative;
		padding-right: 35px;

		&:last-child {
			margin-bottom: 0;
		}

		&__close {
			border: none;
			cursor: pointer;
			outline: none;
			background: none;
			position: absolute;
			top: 10px;
			right: 10px;
			width: 15px;
			height: 15px;
			padding: 0;
			transition: opacity 150ms ease-out;

			&:hover {
				opacity: 0.7;
			}

			svg {
				width: 100%;
				height: 100%;

				path {
					stroke: $c-light;
					stroke-width: 3px;
				}
			}
		}

		&__title {
			font-size: 1em;
			font-weight: 700;
		}

		&__description {
			font-size: 0.9em;
			margin-top: 0.5em;
		}

		&--default {
			background-color: $c-default;

			svg path {
				stroke: $c-dark;
			}
		}
		&--info {
			background-color: $c-info;
			color: $c-light;
		}
		&--success {
			background-color: var(--c__green);
			color: $c-light;
		}
		&--warning {
			background-color: $c-warning;
			color: $c-dark;

			svg path {
				stroke: $c-dark;
			}
		}
		&--error {
			background-color: $c-error;
			color: $c-light;
		}
	}
}
